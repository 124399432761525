import { useMemo } from "react";

export const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="items-centers grid grid-cols-1 justify-between gap-4 border-t border-gray-100 py-[2.125rem] md:grid-cols-2">
          <p className="text-sm max-md:text-center">
            <a href="https://skaner.ai" className="text-black no-underline">
              Skaner wiadomości giełdowych
            </a>{" "}
            © {year}
          </p>
          <div className="flex items-center justify-center space-x-4 text-sm font-semibold text-slate-900 md:justify-end">
            {/* <a href="/articles">Artykuły</a>
            <div className="h-4 w-px bg-slate-200"></div> */}
            <a href="mailto:kontakt@skaner.ai">kontakt@skaner.ai</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
