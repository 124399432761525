import { CompanyScanner } from "./features/CompanyScanner/CompanyScanner";
import { Layout } from "./features/Layout/Layout";
import { NoMatch } from "./components/NoMatch";
import { PortfolioScanner } from "./features/PortfolioScanner/PortfolioScanner";
import { Routes, Route } from "react-router-dom";
import { Login } from "./features/UserAccount/Login";
import { Notification } from "./components/Notification";
import { Article } from "./features/Articles/Article";
import { Register } from "./features/UserAccount/Register";
import { Bookmarks } from "./features/Bookmarks/Bookmarks";
import { SourceScanner } from "./features/SourceScanner/SourceScanner";
import { AccountManagement } from "./features/UserAccount/AccountManagement";
import { ShortDescriptionsUpdater } from "./features/AdminAccount/ShortDescriptionsUpdater";
import { LongDescriptionsUpdater } from "./features/AdminAccount/LongDescriptionsUpdater";
import { CompanyDetailed } from "./features/CompanyScanner/components/CompanyDetailed";

export const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PortfolioScanner />} />
          <Route path="zakladki" element={<Bookmarks />} />
          <Route path="a" element={<Article />} />
          <Route path="c/:ticker" element={<CompanyScanner />} />
          <Route path="c/:ticker/profil" element={<CompanyDetailed />} />
          <Route path="s/:name" element={<SourceScanner />} />
          <Route path="konto" element={<AccountManagement />} />
        </Route>
        <Route path="/admin/" element={<Layout />}>
          <Route path="krotkie-opisy" element={<ShortDescriptionsUpdater />} />
          <Route path="dlugie-opisy" element={<LongDescriptionsUpdater />} />
        </Route>
        <Route path="logowanie" element={<Login />} />
        <Route path="nowe-konto" element={<Register />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Notification />
    </>
  );
};
