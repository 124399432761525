import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { api } from "../../api";
import { Description, Field, Label, Switch } from "@headlessui/react";

export const AccountManagement = () => {
  const [state, setState] = useState({
    login: "",
    isAdmin: false,
    loading: true,
    deviceSync: true,
  });
  useEffect(() => {
    api
      .user()
      .then((response) => {
        setState({
          login: response.userName,
          isAdmin: response.isAdmin,
          loading: false,
          deviceSync: true,
        });
      })
      .catch(() => {
        setState({
          ...state,
          loading: false,
        });
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Zarządzanie kontem – Skaner wiadomości</title>
        <meta
          name="description"
          content={`Wiadomoście giełdowe na temat spółek z GPW i NewConnect z kilkudziesięciu różnych źródeł.`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl items-center px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Zarządzanie kontem
            </h1>
          </div>
          <div className="ml-auto min-w-28 text-right"></div>
        </div>
      </div>
      <div className="mx-auto w-full max-w-5xl px-4 py-[1.125rem] sm:px-6 lg:px-8">
        <Field className="flex items-center justify-between">
          <span className="flex grow flex-col">
            <Label
              as="span"
              passive
              className="text-sm/6 font-medium text-gray-900"
            >
              Synchronizacja na wielu urządzeniach
            </Label>
            <Description as="span" className="text-sm text-gray-500">
              Ustawienia zapisane na jednym urządzeniu będą zapisane na
              wszystkich urządzeniach
            </Description>
          </span>
          <Switch
            checked={state.deviceSync}
            onChange={() =>
              setState({ ...state, deviceSync: !state.deviceSync })
            }
            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-indigo-600"
          >
            <span
              aria-hidden="true"
              className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
            />
          </Switch>
        </Field>
      </div>
    </>
  );
};
