import { CompanyPosts } from "../../components/CompanyPosts";
import { ConfigPanel } from "./components/ConfigPanel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { NoCompanies } from "./components/NoCompanies";
import { NoPosts } from "./components/NoPosts";
import { Helmet } from "react-helmet-async";
import { useRecoilState } from "recoil";
import { selectedCompaniesAtom } from "../../atoms/selectedCompaniesAtom";
import { useEffect } from "react";

export const PortfolioScanner = () => {
  const storageKey = "selectedCompanies";
  const isFirstVisit = localStorage.getItem(storageKey) === null;
  const [storage, setStorage] = useLocalStorage<number[]>(storageKey, []);
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesAtom,
  );

  useEffect(() => {
    setStorage(selectedCompanies);
  }, [selectedCompanies.join(",")]);

  if (selectedCompanies.length === 0) {
    if (isFirstVisit) {
      setSelectedCompanies([83, 105, 283]);
    } else if (storage.length > 0) {
      setSelectedCompanies(storage);
    }
  }

  return (
    <>
      <Helmet>
        <title>Skaner.AI – Skaner wiadomości giełdowych</title>
        <meta
          name="description"
          content="Skaner wiadomości giełdowych wykorzystujący sztuczną inteligencję, czerpiący informacje z kilkudziesięciu źródeł w tym serwisów specjalistycznych i branżowych. "
        />
      </Helmet>
      <div>
        {selectedCompanies.length === 0 ? (
          <NoCompanies />
        ) : (
          <CompanyPosts
            configurable={true}
            companyIds={selectedCompanies}
            postIds={[]}
            sourceIds={[]}
            showCompanyNames={true}
          >
            <NoPosts />
          </CompanyPosts>
        )}
        <ConfigPanel />
      </div>
    </>
  );
};
