import { useState } from "react";
import { api } from "../../api";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../hooks/useNotifications";
import { NotificationType } from "../../enums/notification-type";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export const Login = () => {
  const [_, setUserName] = useLocalStorage<string>("username", "");
  const [__, setToken] = useLocalStorage<{ Authorization: string }>("token", {
    Authorization: "",
  });
  const [state, setState] = useState({ login: "", password: "" });
  const navigate = useNavigate();
  const { setNotification } = useNotifications();

  const login = async () => {
    api
      .login(state.login, state.password)
      .then((response) => {
        setUserName(state.login);
        setToken({ Authorization: `Bearer ${response.accessToken}` });
        setTimeout(() => {
          navigate("/");
        }, 0);
      })
      .catch(() => {
        setNotification("Login failed", NotificationType.Error);
      });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          src="/logo.png"
          className="h-10 w-auto"
          alt="Skaner wiadomości giełdowych"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Zaloguj się
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Adres email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                onChange={(e) => setState({ ...state, login: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Hasło
              </label>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Odzyskaj hasło
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              onClick={login}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Zaloguj się
            </button>
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Nie masz konta?{" "}
          <a
            href="/nowe-konto"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Załóż konto
          </a>
        </p>
      </div>
    </div>
  );
};
