import { Link, useNavigate, useParams } from "react-router-dom";
import { CompanyPosts } from "../../components/CompanyPosts";
import { useEffect, useState } from "react";
import { WideLoader } from "../../components/WideLoader";
import { api } from "../../api";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";

class CompanyScannerState {
  companyIds = [] as number[];
  companyName = "";
  companyDescription = "";
  companyTicker = "";
  hasLongDescription = false;
  isLoading = true;
}

export const CompanyScanner = () => {
  const [state, setState] = useState(new CompanyScannerState());
  const { ticker } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ticker) return;
    (async () => {
      try {
        const company = await api.getBasicCompany(ticker);
        setState({
          ...state,
          companyIds: [company.id],
          companyName: company.name,
          companyTicker: company.ticker,
          companyDescription: company.description,
          hasLongDescription: company.hasLongDescription,
          isLoading: false,
        });
      } catch {
        setState({
          ...state,
          isLoading: false,
        });
      }
    })();
  }, [ticker]);

  if (state.isLoading) return <WideLoader />;
  if (!state.companyName && !state.isLoading) {
    navigate(`/404`);
  }

  return (
    <>
      <Helmet>
        <title>
          {state.companyName} ({state.companyTicker}) – Skaner.AI
        </title>
        <meta name="description" content={state.companyDescription} />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              {state.companyName} ({state.companyTicker})
            </h1>
          </div>
        </div>
        <div className="mx-auto mt-3 flex max-w-5xl px-4 text-sm leading-6 sm:px-6 lg:px-8">
          <p>
            {state.companyDescription}
            {state.hasLongDescription && (
              <div className="pt-3">
                <Link
                  to={`/c/${state.companyTicker.toLocaleLowerCase()}/profil`}
                  className="font-semibold"
                >
                  Profil spółki
                </Link>
              </div>
            )}
          </p>
        </div>
      </div>
      <div>
        {!state.isLoading && state.companyIds.length > 0 && (
          <CompanyPosts
            configurable={false}
            companyIds={state.companyIds}
            sourceIds={[]}
            postIds={[]}
            showCompanyNames={false}
          >
            <div className="my-16 text-center">
              <ArchiveBoxXMarkIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                Brak wiadomości dla wybranej spółki
              </h3>
            </div>
          </CompanyPosts>
        )}
      </div>
    </>
  );
};
