import { useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../hooks/useNotifications";
import { api } from "../../api";
import { NotificationType } from "../../enums/notification-type";

export const Register = () => {
  const [_, setUserName] = useLocalStorage<string>("username", "");
  const [state, setState] = useState({ login: "", password: "" });
  const navigate = useNavigate();
  const { setNotification } = useNotifications();

  const register = async () => {
    api
      .register(state.login, state.password)
      .then((response) => {
        setUserName(state.login);

        if (response.errors.values.length > 0) {
          setNotification("Registration failed", NotificationType.Error);
        }

        setTimeout(() => {
          navigate("/account");
        }, 0);
      })
      .catch(() => {
        setNotification("Registration failed", NotificationType.Error);
      });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          src="/logo.png"
          className="h-10 w-auto"
          alt="Skaner wiadomości giełdowych"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Nowe konto
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Adres email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                onChange={(e) => setState({ ...state, login: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Hasło
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              onClick={register}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Załóż konto
            </button>
          </div>
        </div>
        <p className="mt-10 text-center text-sm text-gray-500">
          Masz już konto?{" "}
          <a
            href="/logowanie"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Zaloguj się
          </a>
        </p>
      </div>
    </div>
  );
};
