import { axiosApi } from "./axiosConfig";
import { defineCancelApiObject } from "./axiosUtils";
import { RegisterResponseDto } from "./dtos/registerResponseDto";
import { Company } from "./entities/company";
import { PaginatedList } from "./entities/paginatedList";
import { Post } from "./entities/post";
import { SourceGroup } from "./entities/source-group";
import { TopCompany } from "./entities/topCompany";
import { User } from "./entities/user";

export const api = {
  getPosts: async function (
    companyIds: number[],
    postIds: number[],
    sourceIds: number[],
    page = 1,
    cancel = false,
  ): Promise<PaginatedList<Post>> {
    const params = new URLSearchParams({
      pageSize: "25",
      pageNumber: page.toString(),
      usefulness: "5",
    });

    companyIds.forEach((id) => params.append("companyIds", id.toString()));
    postIds.forEach((id) => params.append("postIds", id.toString()));
    sourceIds.forEach((id) => params.append("sourceIds", id.toString()));

    const response = await axiosApi.request({
      url: `public/posts?${params.toString()}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getPosts.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getBasicCompany: async function (
    ticker: string,
    cancel = false,
  ): Promise<Company> {
    const response = await axiosApi.request({
      url: `public/company/basic?ticker=${ticker}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getBasicCompany.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
  getDetailedCompany: async function (
    ticker: string,
    cancel = false,
  ): Promise<Company> {
    const response = await axiosApi.request({
      url: `public/company/detailed?ticker=${ticker}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[
            this.getDetailedCompany.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getCompanies: async function (
    companyIds: number[],
    cancel = false,
  ): Promise<Company[]> {
    const params = new URLSearchParams();

    companyIds.forEach((id) => params.append("companyIds", id.toString()));

    const response = await axiosApi.request({
      url: `public/companies?${params.toString()}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  searchCompanies: async function (
    query: string,
    cancel = true,
  ): Promise<Company[]> {
    const response = await axiosApi.request({
      url: `public/search-companies?name=${query}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.searchCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  getSource: async function (
    sourceGroupSimplifiedName: string,
    cancel = false,
  ): Promise<SourceGroup> {
    const response = await axiosApi.request({
      url: `public/source-group?simplifiedName=${sourceGroupSimplifiedName}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  login: async function (
    login: string,
    password: string,
    cancel = false,
  ): Promise<{ status: number; expiresIn: number; accessToken: string }> {
    const response = await axiosApi.request({
      url: `/identity/login`,
      method: "POST",
      data: {
        email: login,
        password,
      },
      signal: cancel
        ? cancelApiObject[this.login.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  register: async function (
    login: string,
    password: string,
    cancel = false,
  ): Promise<RegisterResponseDto> {
    const response = await axiosApi.request({
      url: `/identity/register`,
      method: "POST",
      data: {
        email: login,
        password,
      },
      signal: cancel
        ? cancelApiObject[this.register.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  user: async function (cancel = false): Promise<User> {
    const response = await axiosApi.request({
      url: `/user`,
      method: "GET",
      headers: JSON.parse(localStorage.getItem("token") ?? ""),
      signal: cancel
        ? cancelApiObject[this.user.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  patchCompany: async function (
    id: number,
    fields: { description?: string; longDescription?: string },
    cancel = false,
  ): Promise<boolean> {
    const response = await axiosApi.request({
      url: "admin/company",
      method: "PATCH",
      data: { id, ...fields },
      headers: JSON.parse(localStorage.getItem("token") ?? ""),
      signal: cancel
        ? cancelApiObject[this.patchCompany.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
  getTopShortDescriptionUpdate: async function (
    cancel = false,
  ): Promise<TopCompany> {
    const response = await axiosApi.request({
      url: "admin/company/top-short-description-update",
      method: "GET",
      headers: JSON.parse(localStorage.getItem("token") ?? ""),
      signal: cancel
        ? cancelApiObject[
            this.getTopShortDescriptionUpdate.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getTopLongDescriptionUpdate: async function (
    cancel = false,
  ): Promise<TopCompany> {
    const response = await axiosApi.request({
      url: "admin/company/top-long-description-update",
      method: "GET",
      headers: JSON.parse(localStorage.getItem("token") ?? ""),
      signal: cancel
        ? cancelApiObject[
            this.getTopLongDescriptionUpdate.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

export const cancelApiObject = defineCancelApiObject(api);
