import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useRecoilState } from "recoil";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { openMobileMenuAtom } from "../../../atoms/openMobileMenuAtom";
import { Link } from "react-router-dom";

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useRecoilState(openMobileMenuAtom);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={close} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-xs transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900"></DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={close}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Zamknij</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-2 flex-1 px-6 py-6">
                    <div className="-my-4 items-start space-y-2">
                      <ul className="-mx-2 space-y-1">
                        <li key="">
                          <span className="group flex w-full cursor-pointer content-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
                            <Link to="/" onClick={close}>
                              Strona główna
                            </Link>
                          </span>
                        </li>
                        {/* <li key="">
                          <span className="group flex w-full cursor-pointer content-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
                            <Link to="/logowanie" onClick={close}>
                              Zaloguj się
                            </Link>
                          </span>
                        </li>
                        <li key="">
                          <span className="group flex w-full cursor-pointer content-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
                            <Link to="/nowe-konto" onClick={close}>
                              Załóż konto
                            </Link>
                          </span>
                        </li>*/}
                        <li key="">
                          <span className="group flex w-full cursor-pointer content-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
                            <a href="mailto:kontakt@skaner.ai" onClick={close}>
                              kontakt@skaner.ai
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
