import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CompanySelect } from "./CompanySelect";
import { SelectedCompanies } from "./SelectedCompanies";
import { useRecoilState } from "recoil";
import { isConfigOpenedAtom } from "../../../atoms/isConfigOpenedAtom";
import { portfolioScannerConfigAtom } from "../../../atoms/portfolioScannerConfigAtom";
import { useEffect } from "react";
import { api } from "../../../api";
import { WideLoader } from "../../../components/WideLoader";
import { useDebounce } from "use-debounce";
import { selectedCompaniesAtom } from "../../../atoms/selectedCompaniesAtom";

export const ConfigPanel = () => {
  const [isConfigOpened, setIsConfigOpened] =
    useRecoilState(isConfigOpenedAtom);
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(
    selectedCompaniesAtom,
  );
  const [configState, setConfigState] = useRecoilState(
    portfolioScannerConfigAtom,
  );
  const [loading, setLoading] = useDebounce(true, 100);

  useEffect(() => {
    if (!isConfigOpened) return;

    if (selectedCompanies.length === 0) {
      setConfigState({ ...configState });
      setLoading(false);
      return;
    }

    setConfigState({ ...configState });
    setLoading(true);

    (async () => {
      try {
        const companies = await api.getCompanies(selectedCompanies);
        setConfigState({
          ...configState,
          selectedCompanies: companies,
        });
        setLoading(false);
      } catch {
        setConfigState({
          ...configState,
          selectedCompanies: [],
        });
        setLoading(false);
      }
    })();
  }, [selectedCompanies.join(","), isConfigOpened]);

  const close = () => {
    setSelectedCompanies(configState.selectedCompanies.map((x) => x.id));
    setIsConfigOpened(false);

    setTimeout(() => {
      setConfigState({
        ...configState,
        selectedCompanies: [],
        availableCompanies: [],
      });
    }, 500);
  };

  return (
    <Dialog open={isConfigOpened} onClose={close} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        Dodaj lub usuń spółkę
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={close}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Zamknij</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-2 flex-1 px-4 sm:px-6">
                    <CompanySelect />
                    <div className="mt-2">
                      {loading ? <WideLoader /> : <SelectedCompanies />}
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
