import { Helmet } from "react-helmet-async";
import { CompanyPosts } from "../../components/CompanyPosts";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../api";
import { useEffect, useState } from "react";
import { WideLoader } from "../../components/WideLoader";

export const SourceScanner = () => {
  const [state, setState] = useState({ id: 0, name: "", isLoading: true });
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!name) return;
    (async () => {
      try {
        const source = await api.getSource(name);
        setState({
          ...source,
          isLoading: false,
        });
      } catch {
        setState({
          ...state,
          isLoading: false,
        });
      }
    })();
  }, [name]);

  if (state.isLoading) return <WideLoader />;
  if (!state.name && !state.isLoading) {
    navigate(`/404`);
  }

  return (
    <>
      <Helmet>
        <title>{state.name} – Skaner wiadomości</title>
        <meta
          name="description"
          content={`Wiadomoście giełdowe na temat spółek z GPW i NewConnect z kilkudziesięciu różnych źródeł.`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              {state.name}
            </h1>
          </div>
        </div>
      </div>
      <div>
        <CompanyPosts
          configurable={false}
          showCompanyNames={true}
          sourceIds={[state.id]}
          postIds={[]}
          companyIds={[]}
        >
          <div className="w-full border-b border-gray-100 py-8">
            <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
              Brak wiadomości dla źródła
            </div>
          </div>
        </CompanyPosts>
      </div>
    </>
  );
};
