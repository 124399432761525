import { Link, useNavigate } from "react-router-dom";
import { CompanySearch } from "./CompanySearch";
import { CompanySearchIcon } from "./CompanySearchIcon";
import { useRecoilState } from "recoil";
import { openMobileMenuAtom } from "../../../atoms/openMobileMenuAtom";
import { MobileMenu } from "./MobileMenu";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export const Header = () => {
  const navigate = useNavigate();
  const [_, setIsOpen] = useRecoilState(openMobileMenuAtom);
  const [userName, setUserName] = useLocalStorage("username", "");
  const loggedIn = !!userName;
  return (
    <>
      <nav
        aria-label="Global"
        className="mx-auto max-w-5xl border-b border-b-gray-100 px-4 sm:px-6 lg:px-8"
      >
        <div className="relative flex items-center py-[1.25rem]">
          <div className="flex items-start space-x-0 md:space-x-5">
            <div className="hidden shrink-0 md:block">
              <div className="relative">
                <Link to="/">
                  <img
                    alt=""
                    src="/charlie.jpg"
                    className="mt-2 size-12 rounded-full"
                  />
                  <span
                    aria-hidden="true"
                    className="absolute inset-0 rounded-full shadow-inner"
                  />
                </Link>
              </div>
            </div>
            <div className="pt-1">
              <Link to="/">
                <h1 className="text-2xl font-bold text-gray-900">Skaner.AI</h1>
                <span className="hidden text-sm font-medium text-gray-500 md:block">
                  Skaner wiadomości giełdowych
                </span>
              </Link>
            </div>
          </div>
          <div className="ml-auto hidden lg:flex lg:items-center">
            {/* <Link className="ml-8 text-sm font-semibold" to="/">
              Skaner wiadomości
            </Link> */}
            {/* <Link className="ml-8 text-sm font-semibold" to="/bookmarks">
              Zakładki
            </Link> */}
          </div>
          <CompanySearchIcon />
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            className="-my-1 -mr-1 ml-6 flex h-8 w-8 items-center justify-center"
          >
            <span className="sr-only">Otwórz menu</span>
            <svg viewBox="0 0 24 24" className="h-6 w-6 stroke-slate-900">
              <path
                d="M3.75 12h16.5M3.75 6.75h16.5M3.75 17.25h16.5"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
              ></path>
            </svg>
          </button>
        </div>
      </nav>
      {/* <div className="sticky top-0 z-40 overflow-x-auto whitespace-nowrap border-b border-slate-900/5 bg-transparent transition duration-100">
        <div className="mx-auto flex max-w-5xl justify-between space-x-8 py-4 text-sm font-semibold leading-6 text-slate-900">
          <div className="flex space-x-8 pl-4 sm:pl-6 lg:pl-8">
            <a href="#product-marketing">Marketing</a>
            <a href="#product-application-ui">Application UI</a>
            <a href="#product-ecommerce">Ecommerce</a>
          </div>
          <div className="flex space-x-8 pr-4 sm:pr-6 lg:pr-8"></div>
        </div>
      </div> */}
      <CompanySearch />
      <MobileMenu />
    </>
  );
};
